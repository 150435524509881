.navbar {
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0px;
    left: 0px;

    backdrop-filter: blur(5px);
}

.left {
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    padding-left: 40px;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 25px;
}

.right {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    padding-right: 40px;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 25px;
}

.right>p {
    font-size: 17px;
}

.hoverable:hover {
    cursor: pointer;
    color: var(--high-contrast-color);
}

.left>.active {
    color: var(--high-contrast-color);
}