#experience {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.experience-title {
    color: var(--high-contrast-color);
    font-size: 40px;
}

.experience-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 350px;
}

.choices {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    height: 100%;
    width: 120px;
    background-color: transparent;
}
.choices>p{
    width: 100%;
    flex: 1;
    /* height: 50px; */

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;

    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
    color: var(--main-color);
    border-left: 5px transparent solid;
}
.choices>p:hover{
    border-left: 5px var(--low-contrast-color) solid;
    cursor: pointer;
}
.choices>.active {
    background-color: var(--secondary-background-color);
}

.experience-info {
    position: relative;
    height: 100%;
    width: 500px;
    background-color: var(--secondary-background-color);
    text-align: left;
    
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}

.experience-resume {
    height: 50px;
    width: 150px;
    margin: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--main-color);
    font-size: 18px;
    text-decoration: none;

    background-color: var(--secondary-background-color);
    border: 1px transparent solid;
}
.experience-resume:hover {
    cursor: pointer;
    border-color: var(--main-color);
}

.line{
    margin: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.experience-info p{
    margin: 0px;
}

.education {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.job-title {
    width: calc(100%-20px);
    /* padding: 10px; */
    text-align: center;
    font-size: 20px;
    color: var(--high-contrast-color);
}

.experience-date {
    width: 100%;
    text-align: center;
    color: var(--low-contrast-color);
}

.job-tech {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    bottom: 10px;
    color: var(--low-contrast-color);
    font-size: 14px;
}