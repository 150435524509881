:root {
  --main-color: lightgray;
  --high-contrast-color: white;
  --low-contrast-color: gray;

  --background-color: #181840;
  --secondary-background-color: #31315f;
}

.App {
  text-align: center;
  font-family: 'Courier New', monospace;

  color: var(--main-color);
  background-color: var(--background-color);

  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#content {
  height: 100vh;
  width: 100%;
}

.snap-scroll {
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  scroll-behavior: smooth;
}

.snap-scroll>div {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
}

#content::-webkit-scrollbar {
  display: none;
}
