.contactme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 10px;
}

.link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    color: var(--main-color);
    font-size: 15px;
    text-decoration: none;
}

.reachout {
    color: var(--high-contrast-color);
    font-size: 40px;
}

.link>.Logo {
    fill: var(--main-color);
}