.myprojects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-title {
    color: var(--high-contrast-color);
    font-size: 40px;
}

.project-container {
    width: 800px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.project-card {
    height: 250px;
    width: 250px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-decoration: none
}


.project {
    height: 240px;
    width: 240px;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    background-color: var(--secondary-background-color);
    transition: padding 0.1s linear;
    text-align: left;
}
.project:hover {
    padding: 5px;
}

.project-name {
    position: relative;
    top: 10px;
    left: 20px;
    margin: 0;
    width: 200px;

    color: var(--high-contrast-color);
    font-size: 25px;
}
.project-desc {
    position: relative;
    top: 10px;
    left: 20px;
    margin: 0;
    width: 200px;

    color: var(--main-color);
    font-size: 15px;
}
.project-tech {
    position: absolute;
    left: 20px;
    bottom: 5px;
    color: var(--low-contrast-color);
    font-size: 12px;
}

.project>.ext-link {
    position: absolute;
    right: 5px;
    bottom: 10px;

    width: 30px;
    height: 30px;
    border-radius: 100px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    fill: var(--low-contrast-color);
}
.project>.ext-link:hover{
    fill: var(--high-contrast-color);
}
.project>.gh-link {
    position: absolute;
    right: 40px;
    bottom: 10px;

    width: 30px;
    height: 30px;
    border-radius: 100px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    fill: var(--low-contrast-color);
}
.project>.gh-link:hover{
    fill: var(--high-contrast-color);
}
